import "./pagealacarte.css";
import { useEffect } from "react";
import piment from "../../images/piment.png";
//pizzas
import ariegeoise from "../../images/pizzas/ariegeoise.png";
import basque from "../../images/pizzas/basque.png";
import bolognaise from "../../images/pizzas/bolognaise.png";
import caliente from "../../images/pizzas/caliente.png";
import calzone from "../../images/pizzas/calzone.png";
import campagnarde from "../../images/pizzas/campagnarde.png";
import cannibale from "../../images/pizzas/cannibale.png";
import chorizo from "../../images/pizzas/chorizo.png";
import chorizochevre from "../../images/pizzas/chorizo-chevre.png";
import cinqfromages from "../../images/pizzas/5fromages.png";
import fermiere from "../../images/pizzas/fermiere.png";
import flammekueche from "../../images/pizzas/flammekueche.png";
import gersoise from "../../images/pizzas/gersoise.png";
import gourmande from "../../images/pizzas/Gourmande.png";
import hawaïenne from "../../images/pizzas/hawaÏenne.png";
import indienne from "../../images/pizzas/indienne.png";
import kebab from "../../images/pizzas/kebab.png";
import labergere from "../../images/pizzas/labergere.png";
import littleitaly from "../../images/pizzas/littleitaly.png";
import margarita from "../../images/pizzas/margarita.png";
import MenuOuvrant from "../../componants/menuouvrant/MenuOuvrant";
import napolitaine from "../../images/pizzas/napolitaine.png";
import newyorkaise from "../../images/pizzas/newyorkaise.png";
import norvegienne from "../../images/pizzas/norvegienne.png";
import oceane from "../../images/pizzas/oceane.png";
import orientale from "../../images/pizzas/orientale.png";
import pecheur from "../../images/pizzas/pecheur.png";
import perigourdine from "../../images/pizzas/perigourdine.png";
import quatrefromages from "../../images/pizzas/quatrefromages.png";
import raclette from "../../images/pizzas/raclette.png";
import ratatouille from "../../images/pizzas/ratatouille.png";
import reine from "../../images/pizzas/reine.png";
import rocamadour from "../../images/pizzas/rocamadour.png";
import roquefort from "../../images/pizzas/roquefort.png";
import royale from "../../images/pizzas/royale.png";
import special from "../../images/pizzas/special.png";
import tartiflette from "../../images/pizzas/tartiflette.png";
import thon from "../../images/pizzas/thon.png";
import vegetarienne from "../../images/pizzas/vegetarienne.png";
import DescriptionPizza2 from "../../componants/descriptionpizza2/DescriptionPizza2";
const Pagealacarte = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);

  return (
    <div>
      <div className="menuOuvrantContainer">
        <MenuOuvrant />
      </div>

      <div className="backgroundContainer">
        <div className="h1BaseContainer">
          <h1 className="h1Base">Base Tomate</h1>
        </div>
        <div className="alldetails">
          <div className="Pizza1Container">
            <DescriptionPizza2
              id="Catalane"
              nom="Catalane"
              ingredients="Tomate, Thon, Oignons Rouge, Piquillos, Emmental, Olives "
              prix="12€"
              description="Pizza catalane"
            />
            <p className="pNews">news</p>
            <p className="pExcluWeb">exclu web</p>
          </div>
          <div className="Pizza1Container">
            <DescriptionPizza2
              id="Italienne"
              nom="Italienne"
              ingredients="Tomate, Pesto, Jambon de Pays, Mozzarella, Olives "
              prix="12€"
              description="Pizza Italienne"
            />
            <p className="pNews">news</p>
            <p className="pExcluWeb">exclu web</p>
          </div>
          <DescriptionPizza2
            id="margarita"
            imgPizza={margarita}
            nom="Margarita"
            ingredients="Emmental, Olives"
            prix="9,50€"
            description="Pizza Margarita avec Emmental fondant et olives savoureuses."
          />
          <DescriptionPizza2
            id="reine"
            imgPizza={reine}
            nom="Reine"
            ingredients="Jambon, Emmental, Olives"
            prix="10,50€"
            description="Pizza Reine : pâte à pizza, Jambon, émmental râpé et olives noires. Cuite au four jusqu'à ce que la croûte soit dorée et croustillante."
          />
          <DescriptionPizza2
            id="royale"
            imgPizza={royale}
            nom="Royale"
            ingredients="Jambon, Champignons, emmental, Olives"
            prix="11€"
            description="Délicieuse pizza Royale garnie de jambon, champignons, emmental et olives pour une explosion de saveurs en bouche ! 🍕"
          />
          <DescriptionPizza2
            id="orientale"
            imgPizza={orientale}
            nom="Orientale"
            ingredients="Merguez°, poivrons, oignons,Emmental, Olives"
            prix="11,50€"
            description="Merguez°, poivrons, oignons, Emmental, olives. Délicieusement épicée et savoureuse."
          />
          <DescriptionPizza2
            id="napolitaine"
            imgPizza={napolitaine}
            nom="Napolitaine"
            ingredients="Anchois, câpres, emmental, olives"
            prix="11€"
            description="anchois, câpres, emmental et olives. Savoureuse et garnie d'ingrédients de qualité."
          />
          <DescriptionPizza2
            id="thon"
            imgPizza={thon}
            nom="Thon"
            ingredients="Thon, câpres, emmental, olives"
            prix="11€"
            description="Thon, câpres, emmental, olives. Délicieuse combinaison salée de la mer et fromage fondant."
          />
            <DescriptionPizza2
              id="pecheur"
              imgPizza={pecheur}
              nom="Pecheur"
              ingredients="Cocktail fruits de mer, persillade, emmental, olives"
              prix="11,50€"
              description="Cocktail de fruits de mer, persillade, emmental, olives. Savoureuse et généreuse, une explosion de saveurs marines."
            />
              <DescriptionPizza2
                id="chorizochevre"
                imgPizza={chorizochevre}
                nom="Chorizo Chevre"
                ingredients="Chorizo, chèvre, oignons, poivrons, emmental, olives"
                prix="12,50€"
                description="Découvrez notre pizza Chorizo Chèvre savoureuse, avec ses ingrédients gourmands tels que chorizo, chèvre, oignons, poivrons, emmental et olives !"
              />
              <DescriptionPizza2
                id="vegetarienne"
                imgPizza={vegetarienne}
                nom="Vegetarienne"
                ingredients="Coeur d'artichaut, poivrons, champignons, tomate fraîche, asperges, emmental, olives"
                prix="12,50€"
                description="Succombez à notre pizza végétarienne, une délicieuse combinaison de saveurs pour une expérience gustative unique."
              />
              <DescriptionPizza2
                id="special"
                imgPizza={special}
                nom="Special"
                ingredients="Chorizo, merguez°, oignons, poivrons, emmental, olives"
                prix="13€"
                description="Laissez-vous tenter par notre pizza Special, garnie de chorizo, merguez°, oignons, poivrons, emmental et olives, un vrai délice !"
              />
              <DescriptionPizza2
                id="bolognaise"
                imgPizza={bolognaise}
                nom="Bolognaise"
                ingredients="Oignons, viande de boeuf hachée°, persillade, emmental, olives"
                prix="12,50€"
                description="Plongez dans l'italie avec notre pizza Bolognaise, une explosion de saveurs grâce à notre sauce tomate délicieuse et notre pâte moelleuse."
              />
              <div className="Pizza1Container">
                <DescriptionPizza2
                  id="chorizo"
                  imgPizza={chorizo}
                  nom="Chorizo"
                  ingredients="Chorizo, oignons, poivrons, emmental, olives"
                  prix="11,50€"
                  description="Transportez vos papilles en Espagne avec notre pizza Chorizo, une base de sauce tomate maison sur une pâte croustillante garnie d'olives et de fromage emmental fondant."
                />
              </div>
            <div className="descriptionBaseCremeContainer">
              <div className="h2BaseCremeContainer">
                <p className="h1Base">Base Crème</p>
              </div>
            </div>
              <DescriptionPizza2
                id="flammekueche"
                imgPizza={flammekueche}
                nom="flammekueche"
                ingredients="Oignons, lardons, emmental, olives"
                prix="11€"
                description="Succombez à notre pizza Flammekueche, une spécialité alsacienne avec une base de crème fraîche, des lardons et des oignons, le tout sur une pâte fine et croustillante."
              />
            <DescriptionPizza2
              id="campagnarde"
              imgPizza={campagnarde}
              nom="campagnarde"
              ingredients="Oignons, lardons, pomme de terre, champignons, emmental, olives"
              prix="12€"
              description="Voyagez à la campagne avec notre pizza à base de crème, garnie de lardons croustillants, de champignons, de pommes de terre, d'oignons et d'olives, le tout sur une pâte croustillante et fondante."
            />
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Parmentiere"
                nom="Parmentiere"
                ingredients="Crème, Pomme De Terre,Oignons, Boeuf Hachée, Emmental, Olives "
                prix="15€"
                description="Pizza moussaka"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <DescriptionPizza2
              id="roquefort"
              imgPizza={roquefort}
              nom="roquefort"
              ingredients="Roquefort, noix, emmental, olives"
              prix="13€"
              description="Faites voyager vos papilles avec notre pizza Roquefort, une base de crème fraîche accompagnée de noix croquantes et de fromage emmental fondant, le tout garni de roquefort pour une explosion de saveurs."
            />
            <DescriptionPizza2
              id="indienne"
              imgPizza={indienne}
              nom="Indienne"
              ingredients="Poulet°, curry, poivrons, oignons, emmental, olives"
              prix="13€"
              description="Partez à la découverte de l'Inde avec notre pizza au poulet° et au curry, agrémentée de poivrons et d'oignons, le tout sur une pâte croustillante et recouverte de fromage emmental fondant."
            />
            <DescriptionPizza2
              id="norvegienne"
              imgPizza={norvegienne}
              nom="Norvegienne"
              ingredients="Saumon fumé, aneth, emmental, olives"
              prix="12,50€"
              description="Succombez à notre pizza Norvégienne, une base de crème fraîche sublimée par le saumon fumé et l'aneth, le tout recouvert de fromage emmental fondant et parsemé d'olives."
            />
            <DescriptionPizza2
              id="tartiflette"
              imgPizza={tartiflette}
              nom="Tartiflette"
              ingredients="Pomme de terre, oignons, lardons, reblochon AOP, emmental, olives"
              prix="12,50€"
              description="Dégustez notre pizza Tartiflette, une spécialité savoyarde revisitée sur une base de crème fraîche, avec des pommes de terre fondantes, des lardons croustillants et du reblochon AOP gratiné, le tout garni d'olives et de fromage emmental fondant."
            />
            <DescriptionPizza2
              id="kebab"
              imgPizza={kebab}
              nom="Kebab"
              ingredients="Lamelles kebab°, oignons, poivrons, emmental, olives"
              prix="13€"
              description="Voyagez en Turquie avec notre pizza Kebab°, une base de sauce tomate agrémentée de lamelles de viande kebab°, d'oignons et de poivrons, le tout sur une pâte croustillante et recouverte de fromage emmental fondant et parsemée d'olives."
            />
            <DescriptionPizza2
              id="4fromages"
              imgPizza={quatrefromages}
              nom="4 Fromages"
              ingredients="Tomate ou crème fraiche, reblochon AOP, chèvre, raclette, emmental, olives"
              prix="13,50€"
              description="Fondez de plaisir avec notre pizza 4 fromages, une base de crème fraîche recouverte de reblochon AOP, de chèvre, de raclette et d'emmental pour un mariage parfait des saveurs"
            />

          <div className="DescriptionlesSomptueusesContainer">
            <div className="h2LesSomptueusesContainer">
              <p className="h1Base">Les Somptueuses</p>
            </div>
            <DescriptionPizza2
              id="rocamadour"
              imgPizza={rocamadour}
              nom="Rocamadour"
              ingredients="Crème fraîche, Rocamadour, AOP, pignons de pin, miel, emmental, olives"
              prix="16€"
              description="Découvrez notre pizza Rocamadour, une base de crème fraîche sublimée par le fromage AOP, agrémentée de pignons de pin et de miel pour une touche sucrée-salée, le tout sur une pâte croustillante et recouverte de fromage emmental fondant, avec des olives pour accompagner."
            />
            <DescriptionPizza2
              id="hawaienne"
              imgPizza={hawaïenne}
              nom="Hawaïenne"
              ingredients="Crème fraîche, jambon, poulet°, curry, ananas, emmental, olives"
              prix="16,50€"
              description="Succombez à notre pizza Hawaïenne, une base de crème fraîche garnie de jambon, de poulet° et d'ananas pour une touche exotique, le tout relevé par une pointe de curry, sur une pâte croustillante et recouverte de fromage emmental fondant et parsemée d'olives."
            />
              <DescriptionPizza2
                id="gourmande"
                imgPizza={gourmande}
                nom="Gourmande"
                ingredients="Tomate ou crème fraiche, jambon, lardons, pomme de terre, oeuf, oignons, champignons, emmental, olives"
                prix="15€"
                description="Notre pizza Gourmande est une explosion de saveurs : avec une base de tomate ou crème fraîche, elle est garnie de jambon, de lardons, de pommes de terre, d'œufs, d'oignons et de champignons, surmontée d'un délicieux fromage emmental fondant et agrémentée d'olives pour une touche de gourmandise."
              />
            <DescriptionPizza2
              id="perigourdine"
              imgPizza={perigourdine}
              nom="Perigourdine"
              ingredients="Tomate, magret de canard, gésiers, persillade, emmental, olives"
              prix="15€"
              description="Découvrez notre pizza Périgourdine avec sa base de tomate et son mélange savoureux de magret de canard, de gésiers, de persillade, de fromage emmental fondant et d'olives pour une touche gourmande, le tout sur une pâte croustillante."
            />
            <DescriptionPizza2
              imgPizza={ariegeoise}
              nom="Ariegeoise"
              ingredients="Tomate ou crème fraîche, magret de canard, jambon de pays, bethmale AOP, emmental, olives"
              prix="16,50€"
              description="Laissez-vous tenter par notre pizza Ariégeoise : une base de tomate ou crème fraîche, garnie de magret de canard, de jambon de pays, de bethmale AOP, de fromage emmental fondant et d'olives, le tout sur une pâte croustillante pour une expérience culinaire unique."
            />
            <DescriptionPizza2
              id="cannibale"
              imgPizza={cannibale}
              nom="Cannibale"
              ingredients="Sauce BBQ, jambon, poulet°, viande de boeuf hachée°, merguez°, emmental, olives"
              prix="17€"
              description="Découvrez notre pizza Cannibale, avec une base de sauce BBQ et une garniture savoureuse de jambon, poulet°, viande de boeuf hachée°, merguez°, fromage emmental fondu et olives, le tout sur une pâte croustillante pour une expérience gustative unique."
            />
            <DescriptionPizza2
              id="5fromagessupreme"
              imgPizza={cinqfromages}
              nom="5 Fromages supreme"
              ingredients="Tomate ou crème fraîche, bethmale AOP, compté AOP, roquefort AOP, rocamadour AOP, emmental, olives"
              prix="16,50€"
              description="Notre pizza 5 fromages Supreme est un délice pour les amateurs de fromage avec une base de tomate ou crème fraîche et une sélection de fromages AOP : bethmale, compté, roquefort, rocamadour, emmental fondu, accompagnés d'olives pour une explosion de saveurs."
            />
            <DescriptionPizza2
              id="oceane"
              imgPizza={oceane}
              nom="Oceane"
              ingredients="Crème fraîche, saumon fumé, noix de St jacques, persillade, emmental, olives"
              prix="16,50€"
              description="La délicate crème fraîche de cette pizza est sublimée par la douceur du saumon fumé et la finesse des noix de Saint-Jacques. Le tout est subtilement relevé par une touche de persillade, qui apporte une saveur herbacée et fraîche à chaque bouchée. La générosité de l'emmental fondant viennent parfaire cette symphonie de saveurs."
            />
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Moussaka"
                nom="Moussaka"
                ingredients="Tomate, Aubergine, Oignons, Boeuf Hachée, Persillade, Fromage de Brebis, Emmental, Olives "
                prix="15€"
                description="Pizza moussaka"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="London"
                nom="London"
                ingredients="Crème, Bacon, Cheddar, 2 Oeufs, Lardons, Emmental, Olives"
                prix="15€"
                description="Pizza moussaka"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="L'Aveyronnaise"
                nom="L'Aveyronnaise"
                ingredients="Crème, Roquefort(AOP), Noix, Magret Frais, Magret Fumé, Emmental, Olives"
                prix="17€"
                description="L'Aveyronnaise"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Tacos"
                nom="Tacos"
                ingredients="Sauce Fromagere, Pomme De Terre, Kebab, Merguez, Boeuf Hachée, Cheddar, Mozzarella, Olives"
                prix="16€"
                description="Pizza moussaka"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Samoussas"
                nom="Samoussas"
                ingredients="Sauce Curry, Oignons, Boeuf Hachée, Cumin, Emmental, Olives "
                prix="13€"
                description="Pizza Samoussas"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Frenchi"
                nom="Frenchi"
                ingredients="Crème, Camembert(AOP Au Lait Cru), Poitrine De Porc Fumé, champignons, Emmental, Olives"
                prix="14€"
                description="Pizza Frenchi"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Mexicaine"
                nom="Mexicaine"
                ingredients="Sauce Curry, Chorizo, Poulet, Piquillos, Epice Mexicaine, Emmental, Olives"
                prix="14,50€"
                description="Pizza moussaka"
              />
              <p className="pNews">news</p>
              <p className="pExcluWeb">exclu web</p>
            </div>
            <DescriptionPizza2
              id="littleitaly"
              imgPizza={littleitaly}
              nom="Little Italy"
              ingredients="Tomate, jambon de pays, copeauxde parmesan, roquette, mozarella, huile d'olive, emmental, olives"
              prix="16€"
              description="Dégustez la Little Italy, une explosion de saveurs italiennes ! Jambon de pays, parmesan, roquette, mozzarella... Un vrai régal en bouche !"
            />
            <DescriptionPizza2
              id="labergere"
              imgPizza={labergere}
              nom="La Bergere"
              ingredients="Crème fraîche, fromage de brebis, confiture de cerise noire, jambon de pays, noix, roquette, tomates cerise, emental, olives"
              prix="16€"
              description="Succombez à l'alliance sucré-salé de la confiture de cerise noire, du jambon de pays et du fromage de brebis de la pizza La Bergère, sublimée par la croquante des noix et la fraîcheur de la roquette et des tomates cerise."
            />
            <DescriptionPizza2
              id="raclette"
              imgPizza={raclette}
              nom="Raclette"
              ingredients="Copa, bacon, pomme de terre, lardons, raclette, emmental, olives"
              prix="14,50€"
              description="Dégustez une savoureuse pizza Raclette avec ses ingrédients de qualité : charcuterie, pommes de terre, fromage fondu et une touche d'olives. Un plat généreux qui réchauffe les papilles."
            />
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="newyorkaise"
                imgPizza={newyorkaise}
                nom="NewYorkaise"
                ingredients="Tomate, viande de boeuf hachée°, pomme de terre, oignons rouges, cheddar rouge, oeuf, emmental, olives "
                prix="16€"
                description="Découvrez notre pizza New-Yorkaise pour un voyage gustatif aux Etats-Unis ! Sa viande de boeuf hachée° de qualité, ses pommes de terre croustillantes et ses oignons rouges vous rappelleront les saveurs typiques de la ville qui ne dort jamais"
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="fermiere"
                imgPizza={fermiere}
                nom="Fermiere"
                ingredients="Crème fraîche, poulet°, lardons, cabecou, miel, roquette, crème balsamique, emmental, olives"
                prix="16,50€"
                description="Plongez dans une explosion de saveurs rustiques avec notre pizza fermière! Avec du poulet° savoureux, une touche sucrée de miel et une sauce balsamique crémeuse, chaque bouchée est une invitation à la campagne française. Le mélange de fromages ajoutent la touche finale à cette pizza délicieusement réconfortante."
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="basque"
                imgPizza={basque}
                nom="Basque"
                ingredients="Crème fraîche, piment d'espelette, fromage de brebis, jambon, de Bayonne, chorizo, piquillos, emmental, olives"
                prix="16,50€"
                description="Dégustez l'explosion de saveurs de notre pizza Basque avec son fromage de brebis, jambon de Bayonne, chorizo et piquillos, le tout sublimé par une crème au piment d'Espelette. Un délice !"
                logo={piment}
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="ratatouille"
                imgPizza={ratatouille}
                nom="Ratatouille"
                ingredients="Tomate, poivrons, aubergine grillé, courgette grillé, tomate fraîche, basilic, oeuf, emmental, olives "
                prix="15€"
                description="Dégustez une explosion de saveurs avec la pizza Ratatouille. Des légumes grillés à la fraîcheur de la tomate, en passant par le parfum du basilic, tout est réuni pour un moment de plaisir. Ajoutez-y un oeuf pour une touche gourmande et de l'emmental fondu pour une texture parfaite."
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="calzone"
                imgPizza={calzone}
                nom="Calzone"
                ingredients="Tomate, jambon, champignons, oeuf, oignons rouges, emmental , olives"
                prix="12,50€"
                description="La Calzone, une explosion de saveurs italiennes ! Une pâte croustillante qui renferme une garniture généreuse de jambon, champignons, oignons rouges et emmental fondu, le tout agrémenté d'un œuf parfaitement cuit et de savoureuses olives. Une expérience gustative à ne pas manquer !"
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="Gersoise"
                imgPizza={gersoise}
                nom="Gersoise"
                ingredients="Crème fraîche, gésiers, magret, pomme de terre, persillade, champignons, emmental, olives"
                prix="17€"
                description="Avec ses gésiers et son magret fumé, agrémentés de pommes de terre fondantes et de champignons savoureux. La crème fraîche et l'emmental apportent une douceur crémeuse et fondante, tandis que les olives ajoutent une touche de piquant. Un vrai régal pour les amateurs de cuisine du sud-ouest !"
              />
            </div>
            <div className="Pizza1Container">
              <DescriptionPizza2
                id="caliente"
                imgPizza={caliente}
                nom="Caliente"
                ingredients="Tomate, piquillos, oignons rouges, poulet épicé°, viande de boeuf épicée°, piment d'Espelette, pepperoni, emmental, olives"
                prix="17€"
                description="Dégustez la pizza Caliente et laissez votre palais voyager en Espagne avec ses piquillos et son piment d'Espelette, tandis que son poulet épicé° et sa viande de boeuf épicée° vous feront vibrer. Avec le pepperoni, les oignons rouges et l'emmental fondu, c'est une explosion de saveurs qui vous attend à chaque bouchée."
                logo={piment}
              />
            </div>
          </div>
        </div>
        <div className="pSupplementContainer">
          <p className="pSupplement">"1,50€ supplément ingrédient " </p>
        </div>
        <div className="halalContainer">
          <h2 className="h2Halal">
            ° Viande Halal : poulet, viande hachée, merguez et kebab
          </h2>
        </div>
      </div>
    </div>
  );
};
export default Pagealacarte;
