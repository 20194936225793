import './DescriptionPizza2.css'
const DescriptionPizza2 = (props) => {
    return(
        <div className="allDescriptionPizza2Container">
            <h1 className="h1NomListePizza">{props.nom}</h1>
            <p className="ingredientsListePizza">{props.ingredients}</p>
            <p className="prixListePizza">{props.prix}</p>
            <img className="piment" src={props.logo} />
        </div>
    )
}
export default DescriptionPizza2