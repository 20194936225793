import './PageGlaces.css'
import { useEffect } from 'react';
import menu1 from '../../images/menuglace1.png';
import menu2 from '../../images/menuglace2.png';
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant';

const PageGlaces = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className="allPageGlaces">
            <MenuOuvrant />
            <div className='menuGlaceContainer'>
              <a href='/glacesben'><img className='imgMenu1' alt='img glaces' src={menu1} /></a>
              <a href='/glacesmagnum'><img className='imgMenu2' alt='img glaces' src={menu2} /></a>
            </div>
        </div>
    )
}
export default PageGlaces