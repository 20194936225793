import './PageBen.css';
import { useEffect } from 'react';
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant';
import imgtitre from '../../images/titreglacesben.png';
import CardsGlacesBen from '../../componants/cardsglacesben/CardsGlacesBen';
import glace1 from '../../images/glaces/caramelbrownie.png';
import glace2 from '../../images/glaces/chocolatefudgebrownie.png';
import glace3 from '../../images/glaces/cookiedough.png';
import glace4 from '../../images/glaces/cookievermontster.png';
import glace5 from '../../images/glaces/dulcedelish.png';
import glace6 from '../../images/glaces/halfbaked.png';
import glace7 from '../../images/glaces/netflix.png';
import glace8 from '../../images/glaces/peanutbutter.png';
import glace9 from '../../images/glaces/vegancookiedough.png';
import glace10 from '../../images/glaces/veganfudgebrownie.png';
import logoVegan from '../../images/logovegan.png';
const PageBen = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className='allPageBen'>
            <div className='menuContainer'>
                <MenuOuvrant />
            </div>
            <div className='titreGlacesBenContainer'>
                <img className='imgTitreGlacesBen' src={imgtitre} alt='img' />
            </div>
            <div className='glacesBenContainer'>
                <CardsGlacesBen
                 img={glace1}
                 nom="Caramel Brownie"
                />
                 <CardsGlacesBen
                 img={glace2}
                 nom="Chocolate Fudge Brownie"
                />
                 <CardsGlacesBen
                 img={glace3}
                 nom="Cookie Dough"
                />
                 <CardsGlacesBen
                 img={glace4}
                 nom="Cookie Vermont-ster"
                />
            </div>
            <div className='glacesBenContainer'>
            <CardsGlacesBen
                 img={glace5}
                 nom="Dulce De-lish"
                />
                 <CardsGlacesBen
                 img={glace6}
                 nom="Half Baked"
                />
              <CardsGlacesBen
                 img={glace7}
                 nom="Netflix & Chilll'd "
                />
              <CardsGlacesBen
                 img={glace8}
                 nom="Peanut Butter"
                />
            </div>
            <div className='glacesBenVeganContainer'>
                <div className='logoVeganContainer'>
                    <img className='logoVegan' src={logoVegan} alt='img logo vegan' />
                </div>
            <CardsGlacesBen
                 img={glace9}
                 nom="Cookie Dough"
                />
                 <CardsGlacesBen
                 img={glace10}
                 nom="Fudge Brownie"
                />
            </div>
        </div>
    )
}
export default PageBen