import './App.css';
import { Routes, Route } from "react-router-dom";
import PageAccueil from './pages/pageaccueil/PageAccueil';
import PageGalerie from './pages/pagegalerie/PageGalerie';
import Pagealacarte from './pages/pagealacarte/Pagealacarte';
import PageContact from './pages/pagecontact/PageContact';
import PageGlaces from './pages/pageglaces/PageGlaces';
import Footer from "../src/componants/footer/Footer";
import PageSalades from './pages/pagesalades/PageSalades';
import PagePanini from './pages/pagepanini/PagePanini';
import PageBen from './pages/pageben/PageBen';
import PageGlaceMagnum from './pages/pageglacesmagnum/PageGlaceMagnum';
import PageBoissonsFraiches from './pages/pageboissonsfraiches/PageBoissonsFraiches';
import PageVins from './pages/pagevins/PageVins';
import PageEnSavoirPlus from './pages/pageensavoirplus/PageEnSavoirPlus';
import PagePlaquesApero from './pages/pageplaquesapero/PagePlaquesApero';
import PagePizzaExclusive from './pages/pagepizzaexclusive/PagePizzaExclusive';
function App() {
  return (
    <div className="App">
      <header className="App-header"> 
        <Routes>
            <Route path="/" element={<PageAccueil />} /> 
            <Route exact path="/galerie" element={<PageGalerie />} />
            <Route exact path="/alacarte" element={<Pagealacarte />} />
            <Route exact path="/contact" element={<PageContact />} />
            <Route exact path='/glaces' element={<PageGlaces />} />
            <Route exact path='/salades' element={<PageSalades />} />
            <Route exact path='/panini' element={<PagePanini />} />
            <Route exact path='/glacesben' element={<PageBen />} />
            <Route exact path='/glacesmagnum' element={<PageGlaceMagnum />} />
            <Route exact path='/boissons' element={<PageBoissonsFraiches />} />
            <Route exact path='/vins' element={<PageVins />} />
            <Route exact path="/ensavoirplus" element={<PageEnSavoirPlus />} />
            <Route exact path='/plaquesapero' element={<PagePlaquesApero />} />
            <Route exact path='/exclusive' element={<PagePizzaExclusive />} />
        </Routes>
      </header>
      <footer>
          <Footer />
      </footer>
    </div>
  );
}

export default App;
