import './PageBoissonsFraiches.css'
import { useEffect } from 'react'
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant'
import backgroundPageBoissons from '../../images/backgroundboissons.png'
const PageBoissonsFraiches = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);
    return(
        <div className='allPageBoissonsFraiches'>
          <div className='menuContainer'>
            <MenuOuvrant />
          </div>
          <img src={backgroundPageBoissons} className='backgroundPageBoissons' alt='img soda  ' />
        </div>
    )
}
export default PageBoissonsFraiches