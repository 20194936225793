import React, { useState } from 'react';
import './MenuOuvrant.css';
import logoLaPizzeria from '../../images/LogoLaPizzeria.pdf.png'
const MenuOuvrant = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu-container">
      <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu-nav ${isMenuOpen ? 'open' : ''}`}>
        <ul className='ulMenuOuvrant'>
        <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
          <li>
            <a href="/">
              <span>Menu principal</span>
            </a>
          </li>
          <li>
            <a href="/alacarte">
              <span>Nos Pizzas</span>
            </a>
          </li>
          <li>
            <a href="/panini">
              <span>Nos Paninis</span>
            </a>
          </li>
          <li>
            <a href='/salades'>
              <span>Nos Salades</span>
            </a>
          </li>
          <li>
            <a href='/glaces'>
              <span>Nos Glaces</span>
            </a>
          </li>
          <li>
            <a href="/contact">
              <span>Contact</span>
            </a>
          </li>
          <li>
            <a href="/exclusive">
              <span>Pizzas en exclusivité web</span>
            </a>
          </li>
          <li>
            <a className='aEnSavoirPlus' href="/enSavoirPlus">
              <span className='spanEnSavoirPlus'>En savoir plus</span>
            </a>
          </li>
        </ul>
<div className='filtreMenuOuvrant'>
    <img className='logoMenuLaPizzeria' src={logoLaPizzeria} alt='img logo pizzeria' />
</div>
      </nav>
    </div>
  );
};

export default MenuOuvrant;
