import './PageEnSavoirPLus.css'
import { useEffect } from 'react'
import pizza from '../../images/pizzas/pizzainformation.png'
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant'
const PageEnSavoirPlus = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className="allPageEnSavoirPlusContainer">
            <MenuOuvrant />
            <img className='imgPizzaEnSavoirPlus' src={pizza} alt='img' />
            <p className='pEnSavoirPlus'>Nos Pizzas sont confectionnées avec une farine et une levure de qualité spécialement sélectionnées. <br /> Le temps de pousse de la pate est calculé avec précision.<br /> Notre sauce tomate est "faite maison" et notre emmental est français.</p>
        </div>
    )
}
export default PageEnSavoirPlus