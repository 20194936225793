import "../pageaccueil/pageAccueil.css";
import logoLaPizzeria from "../../images/LogoLaPizzeria.pdf.png";
import imgPizzeria1 from "../../images/comptoir.JPG";
import imgPizzeria2 from "../../images/vins.JPG";
import imgPizzeria3 from "../../images/accueil2.JPG";
import MenuOuvrant from "../../componants/menuouvrant/MenuOuvrant";
import MenuCards from "../../componants/menucards/MenuCards";
import React, { useEffect } from "react";
//Bienvenue dans notre pizzeria ou nous espérons vous transmettre l'amour de la pizza artisanale
const PageAccueil = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);
  
  return (
    <div className="allPageAccueil">
      <MenuOuvrant />
      <div className="part1Container">
        <div className="titreH1H2Container">
          <img
            src={logoLaPizzeria}
            alt="imgLogoLaPizzeria"
            className="imglogopizzeria"
            preload="metadata"
          />
          <a href="/alacarte">
            <button className="buttonALaCarte">À La Carte</button>
          </a>
        </div>
      </div>
      <div className="bandeauContainerAccueil">
        <h2 className="h2BandeauAccueil">Nouvelles pizzas en exclusivité web.</h2>
      </div>
      <div className="menuCardsContainer">
        <MenuCards />
      </div>
      <div className="part2Container">
        <div className="troisImagesContainer">
          <img className="imgpizzeria1" src={imgPizzeria1} alt="img pizzeria" preload="metadata" />
          <img className="imgpizzeria" src={imgPizzeria2} alt="img pizzeria" preload="metadata" />
          <img className="imgpizzeria" src={imgPizzeria3} alt="img pizzeria" preload="metadata" />
        </div>
        <div className="textBottomImgContainer">
          <p className="textBottom1">
            Venez nous voir à la pizzeria pour profiter d'un panel de saveur{" "}
          </p>
          <ul className="textBottom2">
            <a className="ali" href="/alacarte">
              <li>"Pizzas"</li>
            </a>
            <a className="ali" href="/panini">
              <li>"paninis"</li>
            </a>
            <a className="ali" href="/salades">
              <li>"Salades"</li>
            </a>
            <a className="ali" href="/glaces">
              <li>"Glaces"</li>
            </a>
            <a className="ali" href="/boissons">
              <li>"boissons faîches"</li>
            </a>
            <a className="ali" href="/vins">
              <li>"sélection de vins*"</li>
            </a>
            <br />
            <li className="lialcool">
              {" "}
              *l'abus d'alcool est dangeureux pour la santé
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default PageAccueil;
