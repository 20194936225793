import './PageGlaceMagnum.css';
import { useEffect } from 'react';
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant';
import imgtitre from '../../images/titreglacesben.png';
import CardsGlaceBen from '../../componants/cardsglacesben/CardsGlacesBen';
import magnum1 from '../../images/glaces/magnum1.png';
import magnum2 from '../../images/glaces/magnum2.png';
import magnum3 from '../../images/glaces/magnum3.png';
const PageGlaceMagnum = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className='allPageGlaceMagnum'>
            <div className='menuContainer'>
                <MenuOuvrant />
            </div>
            <div className='titreGlacesBenContainer'>
                <img className='imgTitreGlacesBen' src={imgtitre} alt='img' />
            </div>
            <div className='magnumContainer'>
            <CardsGlaceBen
            img ={magnum1}
            nom="Chocolat blanc" />
              <CardsGlaceBen
            img ={magnum2}
            nom="Caramel Beurre Salé" />
              <CardsGlaceBen
            img ={magnum3}
            nom="Amande" />
            </div>
        </div>
    )
}
export default PageGlaceMagnum