import './pagepizzaexclusive.css';
import DescriptionPizza2 from '../../componants/descriptionpizza2/DescriptionPizza2';
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant';
const PagePizzaExclusive = () => {
    return(
        <div className="backgroundContainer">
        <MenuOuvrant />
        <div className="h1BaseContainer">
            <h1 className="h1Base">Nouvelles Pizza En Exclusivité Web</h1>
        </div>
        <div className="alldetails">
        <div className="Pizza1Container">
            <DescriptionPizza2
              id="Catalane"
              nom="Catalane"
              ingredients="Tomate, Thon, Oignons Rouge, Piquillos, Emmental, Olives "
              prix="12€"
              description="Pizza catalane"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
            </div>
            <br />
            <br />
            <div className="Pizza1Container">
               <DescriptionPizza2
              id="Moussaka"
              nom="Moussaka"
              ingredients="Tomate, Aubergine, Oignons, Boeuf Hachée, Persillade, Fromage de Brebis, Emmental, Olives "
              prix="15€"
              description="Pizza moussaka"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
               <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Parmentiere"
              nom="Parmentiere"
              ingredients="Crème, Pomme De Terre,Oignons, Boeuf Hachée, Emmental, Olives "
              prix="15€"
              description="Pizza moussaka"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="London"
              nom="London"
              ingredients="Crème, Bacon, Cheddar, 2 Oeufs, Lardons, Emmental, Olives"
              prix="15€"
              description="Pizza moussaka"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
              </div>
              <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="L'Aveyronnaise"
              nom="L'Aveyronnaise"
              ingredients="Crème, Roquefort(AOP), Noix, Magret Frais, Magret Fumé, Emmental, Olives"
              prix="17€"
              description="L'Aveyronnaise"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Tacos"
              nom="Tacos"
              ingredients="Sauce Fromagere, Pomme De Terre, Kebab, Merguez, Boeuf Hachée, Cheddar, Mozzarella, Olives"
              prix="16€"
              description="Pizza moussaka"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Samoussas"
              nom="Samoussas"
              ingredients="Sauce Curry, Oignons, Boeuf Hachée, Cumin, Emmental, Olives "
              prix="13€"
              description="Pizza Samoussas"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
              </div>
              <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Italienne"
              nom="Italienne"
              ingredients="Tomate, Pesto, Jambon de Pays, Mozzarella, Olives "
              prix="12€"
              description="Pizza Italienne"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Frenchi"
              nom="Frenchi"
              ingredients="Crème, Camembert(AOP Au Lait Cru), Poitrine De Porc Fumé, champignons, Emmental, Olives"
              prix="14€"
              description="Pizza Frenchi"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
               <br />
            <br />
               <div className="Pizza1Container">
              <DescriptionPizza2
              id="Mexicaine"
              nom="Mexicaine"
              ingredients="Sauce Curry, Chorizo, Poulet, Piquillos, Epice Mexicaine, Emmental, Olives"
              prix="14,50€"
              description="Pizza moussaka"
              />
               <p className="pNews">news</p>
               <p className="pExcluWeb">exclu web</p>
               </div>
            </div>
            <br />
            <br />
        </div>
    )
}
export default PagePizzaExclusive