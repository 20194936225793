import "./PagePanini.css";
import { useEffect } from "react";
import MenuOuvrant from "../../componants/menuouvrant/MenuOuvrant";
import DescriptionPizza2 from "../../componants/descriptionpizza2/DescriptionPizza2";
//panini
import ariegeois from '../../images/panini/ariegeois.png';
import chorizochevre from '../../images/panini/chorizochevre.png';
import fraicheur from "../../images/panini/fraicheur.png";
import italien from '../../images/panini/italien.png';
import jambonblanc from '../../images/panini/jambonblanc.png';
import kebab from '../../images/panini/kebab.png';
import pouletcurry from '../../images/panini/pouletcurry.png';
import oriental from '../../images/panini/oriental.png';
import quatrefromages from '../../images/panini/quatrefromages.png';
import saumonfume from "../../images/panini/saumonfume.png";

const PagePanini = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);
  return (
    <div className="allPagePanini">
      <div className="menuContainer">
        <MenuOuvrant />
      </div>
      <div className="nosPaniniContainer">
        <div className="backgroundContainerPanini">
          <div className="allDetails">    
        <h2 className="h2Panini">Nos Paninis</h2>
      <DescriptionPizza2
      id="jambonblanc"
      prix="4,50€"
      nom="Jambon Blanc"
      imgPanini={jambonblanc}
      description="Panini Jambon blanc, des Saveurs gourmandes à chaque bouchée !"
      ingredients="Crème fraîche, jambon blanc, mozzarella"
      />
       <DescriptionPizza2
        id="fraicheur"
        prix="4,50€"
        nom="Fraicheur"
        imgPanini={fraicheur}
        description="Panini fraîcheur : Un mélange de saveurs vives et délicieuses !"
        ingredients="Tomate fraîche, basilic, mozzarella"
      />
       <DescriptionPizza2
        id="quatrefromages"
        prix="5,50€"
        nom="4 fromages"
        imgPanini={quatrefromages}
        description="Panini 4 fromages : Un plaisir fromager irrésistible !"
        ingredients="Chèvre, emmental, raclette, mozzarella"
      />
       <DescriptionPizza2
        id="saumonfume"
        prix="5,50€"
        nom="Saumon Fumé"
        imgPanini={saumonfume}
        description="Panini saumon fumé : Un délice fumé à savourer !"
        ingredients="Crème fraîche, saumon fumé, mozzarella"
      />
       <DescriptionPizza2
        id="pouletcurry"
        prix="5,50€"
        nom="Poulet Curry"
        imgPanini={pouletcurry}
        description="Panini poulet curry : Un mariage épicé et crémeux !"
        ingredients="Crème fraîche, poulet curry°, mozzarella"
      />
       <DescriptionPizza2
        id="kebab"
        prix="5,50"
        nom="Kebab"
        imgPanini={kebab}
        description="Panini kebab : Un délice savoureux et épicé !"
        ingredients="Crème Fraîche, lamelles kebab, mozzarella"
      />
       <DescriptionPizza2
        id="italien"
        prix="5,50"
        nom="Italien"
        imgPanini={italien}
        description="Panini Italien : Une explosion de saveurs à l'italienne !"
        ingredients="Crème fraîche, pesto, jambon de pays, mozzarella"
      />
       <DescriptionPizza2
        id="ariegeois"
        prix="6,50"
        nom="Ariègeois"
        imgPanini={ariegeois}
        description="Panini Ariégeois : Un festin Ariégeois entre deux pains !"
        ingredients="Crème fraîche, bethmale, magret fumé, jambon de pays, mozzarella"
      />
       <DescriptionPizza2
        id="chorizochevre"
        prix="5,50"
        nom="Chorizo Chevre"
        imgPanini={chorizochevre}
        description="Panini chorizo chèvre : Un mariage audacieux de saveurs et de piquant !"
        ingredients="Crème fraîche, chorizo, chèvre, oignons, mozzarella"
      />
        <DescriptionPizza2
        id="oriental"
        prix="5,50"
        nom="Oriental"
        imgPanini={oriental}
        description="Panini oriental : Une explosion de saveurs épicées et exotiques !"
        ingredients="Crème fraîche, merguez, oignons, mozzarella"
      />
      </div>
      </div>
      </div>
    </div>
  );
};
export default PagePanini;
