import './PageVins.css'
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant'
import vins from '../../images/vins.JPG'
import { useEffect } from 'react'
const PageVins = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className="allPageVins">
            <div className='menuContainer'>
                <MenuOuvrant />
            </div>
            <div className='imgPageVinsContainer'>
                <h1 className='h1PageVins'>Laissez-vous séduire par notre sélection de vins soigneusement choisis pour satisfaire tous les palais et toutes les occasions.</h1>
            <div className='imgVinsContainer'>
                <img className='imgVins' alt='img bouteilles de vins' src={vins} />
                <p className='pVins'>En vente dans notre pizzeria</p>
            </div>
            </div>
        </div>
    )
}
export default PageVins