// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/backgroundglacesviolet.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".allPageGlaceMagnum{\n    display: flex;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.backgroundPageGlaceMagnum{\n    width: 100%;\n}\n.allPageGlaceMagnum{\n    display: flex;\n    flex-direction: column;\n}\n\n\n/* grand écran */\n@media screen and (min-width : 768px){\n    .allPageGlaceMagnum{\n        min-height: 100vh;\n    }\n    .magnumContainer{\n        width: 100%;\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n        flex-direction: row;\n    }\n}", "",{"version":3,"sources":["webpack://./src/pages/pageglacesmagnum/PageGlaceMagnum.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yDAAgE;AACpE;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;;AAGA,gBAAgB;AAChB;IACI;QACI,iBAAiB;IACrB;IACA;QACI,WAAW;QACX,aAAa;QACb,6BAA6B;QAC7B,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":[".allPageGlaceMagnum{\n    display: flex;\n    background-image: url('../../images/backgroundglacesviolet.png');\n}\n.backgroundPageGlaceMagnum{\n    width: 100%;\n}\n.allPageGlaceMagnum{\n    display: flex;\n    flex-direction: column;\n}\n\n\n/* grand écran */\n@media screen and (min-width : 768px){\n    .allPageGlaceMagnum{\n        min-height: 100vh;\n    }\n    .magnumContainer{\n        width: 100%;\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n        flex-direction: row;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
