import "./pageGalerie.css";
import { useEffect } from "react";
import Galerie from "../../componants/galerie/Galerie";
import MenuOuvrant from "../../componants/menuouvrant/MenuOuvrant";
const PageGalerie = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);
  return (
    <div className="allPageGalerie">
      <MenuOuvrant />
      <h1 className="h1Galerie">nos création culinaire en images</h1>
      <Galerie
        galerie1Image1="https://cdn.pixabay.com/photo/2016/11/29/13/02/cheese-1869708_960_720.jpg"
        galerie1Image2="https://cdn.pixabay.com/photo/2017/09/30/15/10/plate-2802332_960_720.jpg"
        galerie1Image3="https://cdn.pixabay.com/photo/2017/01/03/11/33/pizza-1949183_960_720.jpg"
        galerie2Image1="https://cdn.pixabay.com/photo/2017/09/30/15/10/plate-2802332_960_720.jpg"
        galerie2Image2="https://cdn.pixabay.com/photo/2017/08/10/05/44/pizza-2618726_960_720.jpg"
        galerie3Image1="https://cdn.pixabay.com/photo/2018/04/07/15/03/pizza-3298685_960_720.jpg"
        galerie3Image2="https://cdn.pixabay.com/photo/2015/03/17/11/36/blackboard-677578_960_720.jpg"
        galerie3Image3="https://cdn.pixabay.com/photo/2018/01/14/20/49/dough-3082589_960_720.jpg"
        galerie3Image4="https://cdn.pixabay.com/photo/2016/08/25/19/38/baker-1620504_960_720.jpg"
      />
      <Galerie
        galerie1Image1="https://cdn.pixabay.com/photo/2014/10/22/18/12/bistro-498504_960_720.jpg"
        galerie1Image2="https://cdn.pixabay.com/photo/2021/09/02/13/36/pizza-6593504_960_720.jpg"
        galerie1Image3="https://cdn.pixabay.com/photo/2018/03/04/20/05/pizza-3199081_960_720.jpg"
        galerie2Image1="https://cdn.pixabay.com/photo/2020/04/29/03/30/pizza-5107039_960_720.jpg"
        galerie2Image2="https://cdn.pixabay.com/photo/2016/07/26/16/23/pizza-1543198_960_720.jpg"
        galerie3Image1="https://cdn.pixabay.com/photo/2018/04/07/15/03/pizza-3298685_960_720.jpg"
        galerie3Image2="https://cdn.pixabay.com/photo/2015/03/17/11/36/blackboard-677578_960_720.jpg"
        galerie3Image3="https://cdn.pixabay.com/photo/2020/06/17/21/46/pizza-5311269_960_720.jpg"
        galerie3Image4="https://cdn.pixabay.com/photo/2015/07/24/06/08/pizza-857884_960_720.jpg"
      />
    </div>
  );
};
export default PageGalerie;
