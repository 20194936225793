import './MenuCards.css'
import pizza from '../../images/pizzas/pizzainformation.png'
import salades from '../../images/salades/saladeslapizzeria.jpg'
import apero from '../../images/plaquesapero/plaque4.png'
import panini from "../../images/panini.png";
const MenuCards = () => {
    return(
        <div className="allMenuCardsContainer">
          <div className='menu1'>
         <div className="cardMenuContainer">
           <a href='/alacarte'>
            <div className="imgcardMenuContainer">
              <img src={pizza} className='imgCard1Menu' alt='img pizza'/>
            </div>
            <div className='textCardMenuContainer'>
                <p className='textCardMenu'>Pizzas</p>
            </div>
          </a>
         </div>
         <div className="cardMenuContainer">
         <a href='/panini'>
            <div className="imgcardMenuContainer">
              <img src={panini} className='imgCard1Menu' alt='img panini'/>
            </div>
            <div className='textCardMenuContainer'>
                <p className='textCardMenu'>Paninis</p>
            </div>
            </a>
         </div>
        
         <div className="cardMenuContainer">
         <a href='/salades'>
            <div className="imgcardMenuContainer">
              <img src={salades}className='imgCard1Menu' alt='img salades'/>
            </div>
            <div className='textCardMenuContainer'>
                <p className='textCardMenu'>Salades</p>
            </div>
         </a>
         </div>
         <div className="cardMenuContainer">
         <a href='/plaquesapero'>
            <div className="imgcardMenuContainer">
              <img src={apero}className='imgCard3Menu' alt='img salades'/>
            </div>
            <div className='textCardMenuContainer'>
                <p className='textCardMenu'>Plaques Apéro</p>
            </div>
         </a>
         </div>
         </div>
         </div>
    )
}
export default MenuCards