import './PageContact.css';
import pizzeria from '../../images/contactpizzeria.png'
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant';
import { useEffect } from 'react';
const PageContact = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className="allContact">  
        <div className='navBarContactContainer'>
          <MenuOuvrant />
        </div>
        <div className='bloc1et2Container'>
        <div className="bloc1Contact">
            <h1> La Pizzeria</h1>
            <h2>savoir faire des artisants</h2>
            <div className='rsContactContainer'>
                <a href='https://www.instagram.com/lapizzeria8689/'><img className='rs1' alt='img instagram' src='https://cdn.pixabay.com/photo/2018/11/13/22/01/instagram-3814080_1280.png'/></a>
                <a href='https://www.instagram.com/'><img className='rs1' alt='img facebook'src='https://cdn.pixabay.com/photo/2015/05/17/10/51/facebook-770688_960_720.png'/></a>
            </div>
            <div className='imgPizzeriaContainer'>
                <img className='imgPizzeria' alt='img pizzeria' src={pizzeria} />
            </div>
        </div>
        <div className="bloc2Contact">
            <div className="horairesContact">
                <h2>Horaires de La Pizzeria</h2>
                <hr></hr>
                <p>lundi : 11h-13h   18h-21h</p>
                <hr></hr>
                <p>mardi : 11h-13h   18h-21h</p>
                <hr></hr>
                <p>mercredi : fermé </p>
                <hr></hr>
                <p>jeudi : 11h-13h   18h-21h</p>
                <hr></hr>
                <p>vendredi: 11h-13h   18h-21h</p>
                <hr></hr>
                <p>samedi : 11h-13h   18h-21h</p>
                <hr></hr>
                <p>dimanche : 18h-21h </p>
                <hr></hr>
            </div>
            <p>Adresse : 21 Avenue Victor Pilhes Tarascon-sur-Ariege </p>
            <p>Tel : 09.85.06.17.68</p>
            </div>
            </div>
        </div>
    )
}
export default PageContact