import "./PageSalades.css";
import MenuOuvrant from "../../componants/menuouvrant/MenuOuvrant";
import DescriptionPizza2 from "../../componants/descriptionpizza2/DescriptionPizza2";
import { useEffect } from "react";
//salades

const PageSalades = () => {
  useEffect(() => {
    document.body.style.display = "block";
  }, []);
  return (
    <div className="allPageSalades">
      <div className="nosSaladesContainer">
        <MenuOuvrant />
      <div className="backgroundContainerSalades">
      <div className="filtreSalades"></div>
      <div className="h1SaladesContainer">
        <h1 className="h1PageSalades">
          Si vous recherchez un plat léger et rafraîchissant qui vous procurera
          une sensation de bien-être, une gamme de salades fraîches est la
          solution idéale.
        </h1>
      </div>
        <DescriptionPizza2
          id="cesar"
          prix="8€"
          nom="Cesar"
          ingredients="Salade,poulet,tomate,parmesan,croûtons, sauce césar"
          descriptionSalade="
            Découvrez la fraîcheur et la légèreté de la Salade César : une symphonie de textures croquantes, de saveurs délicates et d'une sauce envoûtante."
        />
        <DescriptionPizza2
          id="quatresaisons"
          prix="8€"
          nom="4 saisons"
          ingredients="Salade,tomate,coeur d'artichaut,maîs,asperges,oignons rouges,olives noires"
          descriptionSalade="Découvrez la Salade 4 Saisons et laissez-vous séduire par cette harmonie gustative où chaque bouchée est une invitation à savourer la diversité des saisons. "
        />
        <DescriptionPizza2
          id="littleitaly"
          prix="8€"
          nom="Little Italy"
          ingredients="Salade, tomate, bethmale, jambon de pays, parmesan, mozzarella, tomates confites "
          description=" Laissez-vous transporter par l'authenticité de l'Italie "
        />
        <DescriptionPizza2
          id="ariegeoise"
          prix="8€"
          nom="Ariegeoise"
          ingredients="Salade, tomate, bethmale, jambon de pays, magret fumé"
          descriptionSalade="Découvrez la Salade Ariégeoise, une véritable invitation au terroir et aux saveurs "
        />
        <DescriptionPizza2
          id="auvergnate"
          prix="8€"
          nom="Auvergnate"
          ingredients="Salade, tomate, bethmale, jambon de pays, roquefort, noix"
          descriptionSalade="Laissez-vous envoûter par la Salade Auvergnate, une symphonie de saveurs authentiques qui vous transporte au cœur de la région."
        />
      </div>
      </div>
    </div>
  );
};
export default PageSalades;
