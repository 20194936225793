import '../footer/footer.css'
import imghaut from '../../images/imgfooterhaut.png';
import imgbas from '../../images/imgfooterbas.png';
import instagram from "../../images/insta.png";
import facebook from "../../images/facebook.png";
const Footer = () => {
    return(
        <div className="allFooter">
            <div className='allColonnes'>
            <div className="colonne1">
             <img className='imgHaut' src={imghaut} alt="img décoration" />
             <h2 className="h2Footer">Nous Contacter</h2>
             <p className='tel'>Tel : 09.85.06.17.68</p>
             <p className='mail'>Adresse : 21 Avenue Victor Pilhes Tarascon-sur-Ariege</p>
             <div className='rsContactContainer'>
                <a href='https://www.instagram.com/lapizzeria8689/'><img className='rs1' alt='img instagram' src={instagram}/></a>
                <a href='https://www.instagram.com/'><img className='rs1' alt='img facebook'src={facebook}/></a>
            </div>
             <img className='imgHaut' src={imgbas} alt='img décoration' />                
            </div>
            </div>
        </div>
    )
}
export default Footer