// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/backgroundglaces.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".allPageGlaces{\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    height: auto;\n    background-size: cover;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.menuGlaceContainer{\n    margin-top: 3em;\n}\n.imgMenu1{\n    margin-top: 5em;\n    width: 22em;\n    transition-duration: 0.5s;\n}\n.imgMenu2{\n    width: 22em;\n    transition-duration: 0.5s;\n}\n.imgMenu1:hover{\n    transition-duration: 0.5s;\n    transform: scale(1.2);\n}\n.imgMenu2:hover{\n    transform: scale(1.2);\n}\n.h1Ben{\n    color: red;\n}", "",{"version":3,"sources":["webpack://./src/pages/pageglaces/PageGlaces.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,yDAAwD;AAC5D;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,UAAU;AACd","sourcesContent":[".allPageGlaces{\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    height: auto;\n    background-size: cover;\n    background-image: url(../../images/backgroundglaces.png);\n}\n.menuGlaceContainer{\n    margin-top: 3em;\n}\n.imgMenu1{\n    margin-top: 5em;\n    width: 22em;\n    transition-duration: 0.5s;\n}\n.imgMenu2{\n    width: 22em;\n    transition-duration: 0.5s;\n}\n.imgMenu1:hover{\n    transition-duration: 0.5s;\n    transform: scale(1.2);\n}\n.imgMenu2:hover{\n    transform: scale(1.2);\n}\n.h1Ben{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
