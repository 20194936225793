// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200&family=Rowdies:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".allCardsGlacesBenContainer{\n    \n}\n.imgCardGlaceBen{\n    width: 70%;\n}\n.nomGlaceBen{\n    margin-top: -0px;\n}\n\n/* grand écran */\n@media  screen and (min-width: 720px) {\n.allCardsGlacesBenContainer{\n  \n}\n.imgCardGlaceBen{\n  width: 100%;\n  height: 50vh;\n  transition-duration: 0.5s;\n}\n.imgCardGlaceBen:hover{\n    transition-duration: 1s;\n    transform: scale(1.1);\n}\n.nomGlaceBen{\n    margin-top: -40px;\n    font-family: 'Dancing Script', cursive;\n}\n}", "",{"version":3,"sources":["webpack://./src/componants/cardsglacesben/CardsGlacesBen.css"],"names":[],"mappings":"AACA;;AAEA;AACA;IACI,UAAU;AACd;AACA;IACI,gBAAgB;AACpB;;AAEA,gBAAgB;AAChB;AACA;;AAEA;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;AACA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;AACA;IACI,iBAAiB;IACjB,sCAAsC;AAC1C;AACA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200&family=Rowdies:wght@300&display=swap');\n.allCardsGlacesBenContainer{\n    \n}\n.imgCardGlaceBen{\n    width: 70%;\n}\n.nomGlaceBen{\n    margin-top: -0px;\n}\n\n/* grand écran */\n@media  screen and (min-width: 720px) {\n.allCardsGlacesBenContainer{\n  \n}\n.imgCardGlaceBen{\n  width: 100%;\n  height: 50vh;\n  transition-duration: 0.5s;\n}\n.imgCardGlaceBen:hover{\n    transition-duration: 1s;\n    transform: scale(1.1);\n}\n.nomGlaceBen{\n    margin-top: -40px;\n    font-family: 'Dancing Script', cursive;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
