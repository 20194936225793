import './galerie.css'
const Galerie = (props) => {
    return(
        <div className="allGalerie">
        <div className='galerie1Container'>
        <div className="galerie1Image1Container">
            <img className="galerie1Image1" src={props.galerie1Image1} alt="img pizza" />
        </div>
        <div className='galerie1Part2Container'>
            <img className='galerie1Image2' src={props.galerie1Image2} alt="img pizza" />
            <img className='galerie1Image3' src={props.galerie1Image3} alt="img pizza" />
        </div>
        </div>
        <div className='galerie2Container'>
        <img className="galerie2Image1" src={props.galerie2Image1} alt="img pizza" />
        <img className="galerie2Image2" src={props.galerie2Image2} alt="img pizza" />
        </div>
        <div className='galerie3Container'>
        <div className='galerie3Part1Container'>
        <img className='galerie3Image1' src={props.galerie3Image1} alt="img pizza" />
        <img className='galerie3Image2' src={props.galerie3Image2} alt="img pizza" />
        </div>
        <div className='galerie3part2Container'>
        <img className='galerie3Image3' src={props.galerie3Image3} alt="img pizza" />
        <img className='galerie3Image4' src={props.galerie3Image4} alt="img pizza" />
        </div>
        </div>
        </div>
    )
}
export default Galerie