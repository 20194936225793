import './PagePlaquesApero.css'
import { useEffect } from 'react'
import MenuOuvrant from '../../componants/menuouvrant/MenuOuvrant'
import apero1 from '../../images/plaquesapero/plaque5.jpg'
import apero2 from '../../images/plaquesapero/plaque2.jpg'

const PagePlaquesApero = () => {
    useEffect(() => {
        document.body.style.display = "block";
      }, []);
    return(
        <div className='allPagePlaquesApero'>
               <div className='menuContainer'>
                <MenuOuvrant />
            </div>
            <div className='paperoContainer'>

            <p className='papero'>Nous sommes ravis de vous présenter les plaques Apéro ! 
Nos plaques Apéro mesurent 60x40cm, offrant la taille parfaite pour ravir vos papilles et celles de vos amis. Imaginez-vous déguster l'équivalent de quatre succulentes pizzas, le tout pour le prix de trois !
Ce qui distingue nos plaques Apéro, c'est la liberté de choix qu'elles offrent. Vous pouvez choisir parmi notre gamme complète de pizzas, allant des classiques incontournables aux somptueuses, notre carte des pizzas saura combler toutes vos envies</p>
            </div>
         <div className='imgAperoContainer'>
            <img src={apero1} className='imgApero' alt='img' />
            <img src={apero2} className='imgApero' alt='img' />
         </div>
        <p>Nous consulter pour les prix</p>
        </div>
    )
}
export default PagePlaquesApero